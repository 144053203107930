import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import { TableBody, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import Tab1 from './tab1';
import Tab2 from './tab2';
import Tab3 from './tab3';
import useCreateQuote from './quotesStore';
import useTab1Validation from './useTab1Validation';
import useTab2Validation from './useTab2Validation';
import sampleData from './sample.json';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const clientInformation = useCreateQuote((state) => state.clientInformation);
  const volumes = useCreateQuote((state) => state.volumes);
  const coversInformation = useCreateQuote((state) => state.coversInformation);

  const validationTab1 = useTab1Validation();
  const validationTab2 = useTab2Validation();

  const [result, setResult] = React.useState(null);
  const [error, setError] = React.useState(null);

  const { width, height } = useWindowSize();

  const checkIfTab1IsValid = () => {
    if (clientInformation?.ageLimit < 18 || clientInformation?.ageLimit > 100) {
      return true;
    }
    if (validationTab1) {
      alert('Please fill in client information');
      return true;
    }
    return false;
  };

  const checkIfTab2IsValid = () => {
    if (clientInformation?.ageLimit < 18 || clientInformation?.ageLimit > 100) {
      return true;
    }
    if (validationTab2) {
      alert('Please fill in client information');
      return true;
    }
    return false;
  };

  const handleChange = (event, newValue) => {
    // 0 means tab1
    // 1 means tab2
    // 2 means tab3
    if (newValue === 1) {
      if (checkIfTab1IsValid()) {
        return;
      }
    }
    if (newValue === 2) {
      if (checkIfTab1IsValid() || checkIfTab2IsValid()) {
        return;
      }
    }
    setValue(newValue);
  };

  const handleQuoteCreate = async () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/quote`,
        {
          clientInformation,
          coversInformation,
          volumes,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokens?.access?.token}` || '',
          },
        }
      )
      .then((d) => {
        console.log(d);
        window.open(`${window.location.origin}/generated/quote/${d?.data?.newQuote?.id}`);
      })
      .catch((e) => {
        setError(e.response.data.message);
      });
  };

  const downloadQuote = async () => {};

  const handleClose = () => {
    setResult(null);
    setError(null);
  };

  const isValid = () => {
    let isError = true;
    coversInformation
      .filter((item) => item.type === 'mandatory')
      .forEach((item) => {
        if (item.sumInsured === '0' || item.sumInsured === 0) {
          isError = true;
        }
        if (item.sumInsured) {
          if (item.sumInsured >= item.minValue || item.sumInsured <= item.maxValue) {
            isError = false;
          } else {
            isError = true;
          }
        } else {
          isError = true;
        }
      });
    return isError;
  };

  return (
    <Box sx={{ width: '100%' }}>
      {error && <Alert severity="error">{error}</Alert>}
      <Dialog
        open={result !== null}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <Confetti width={width} height={height} run={result !== null} /> */}
        <DialogTitle>Voila! You are just a step away from the bingo deal!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                padding: '10px 10px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>Master Policy Holder Name</div>
                <div style={{ fontWeight: 600 }}>{clientInformation?.clientName}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>Sum Insured</div>
                <div style={{ fontWeight: 600 }}>Rs. 1230</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>Total Number of Covers</div>
                <div style={{ fontWeight: 600 }}> {coversInformation?.length}</div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>Maximum Trip Duration chosen</div>
                <div style={{ fontWeight: 600 }}>{volumes?.maxTripDuration} days</div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div> Per Person Per day rate Pre GST</div>
              </div>

              <Table
                size="small"
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">US</TableCell>
                    <TableCell align="center">Non - US</TableCell>
                    <TableCell align="center">Asia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Rs. {result?.finalRateUS.toFixed(2)}</TableCell>
                    <TableCell align="center">Rs. {result?.finalRateNonUS.toFixed(2)}</TableCell>
                    <TableCell align="center">Rs. {result?.finalRateAsia.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={downloadQuote}>Download Quote Now</Button>
          <Button onClick={handleClose} color="error">
            Edit quote
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Let's have some Client Information" {...a11yProps(0)} />
          <Tab label="Keep the Volumes Coming!" {...a11yProps(1)} />
          <Tab label="Let's cover you!" {...a11yProps(2)} />
          {value === 2 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: '20px',
                flex: 1,
              }}
            >
              <Button color="primary" variant="contained" onClick={handleQuoteCreate} disabled={isValid()}>
                Generate Quote
              </Button>
            </div>
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Tab1 />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Tab2 />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Tab3 />
      </TabPanel>
    </Box>
  );
}
