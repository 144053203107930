import React from 'react';
import { TextField } from '@mui/material';
import Dropdown from './dropdown';
import {
  businessTypeOptions,
  geographyOptions,
  groupCategoryOptions,
  lobOptions,
  typeOfRiskOptions,
} from './constants';
import useCreateQuote from './quotesStore';

const Tab1 = () => {
  const clientInformation = useCreateQuote((state) => state.clientInformation);
  const setClientInformation = useCreateQuote((state) => state.setClientInformation);

  const handleLobChange = (data) => {
    setClientInformation({ ...clientInformation, lob: data });
  };

  const handleGeoChange = (data) => {
    setClientInformation({ ...clientInformation, geography: data });
  };

  const handleBusinessChange = (data) => {
    setClientInformation({ ...clientInformation, businessType: data });
  };

  const handleCategoryChange = (data) => {
    setClientInformation({ ...clientInformation, groupCategory: data });
  };

  const handleRiskChange = (data) => {
    setClientInformation({ ...clientInformation, typeOfRisk: data });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
      }}
    >
      <Dropdown options={lobOptions} label="LOB" selected={clientInformation?.lob} setSelected={handleLobChange} />
      <Dropdown
        options={geographyOptions}
        label="Geography"
        selected={clientInformation?.geography}
        setSelected={handleGeoChange}
      />
      <TextField
        id="outlined-basic"
        label="Name of the Client"
        variant="outlined"
        size="small"
        value={clientInformation?.clientName}
        onChange={(e) => setClientInformation({ ...clientInformation, clientName: e.target.value })}
      />
      <Dropdown
        options={businessTypeOptions}
        label="Business Type"
        selected={clientInformation?.businessType}
        setSelected={handleBusinessChange}
      />
      <TextField
        id="outlined-basic"
        label="Industry Type"
        variant="outlined"
        size="small"
        value={clientInformation?.industryType}
        onChange={(e) => setClientInformation({ ...clientInformation, industryType: e.target.value })}
      />
      <TextField
        id="outlined-basic"
        label="Location of the Client"
        variant="outlined"
        size="small"
        value={clientInformation?.location}
        onChange={(e) => setClientInformation({ ...clientInformation, location: e.target.value })}
      />
      <Dropdown
        options={groupCategoryOptions}
        label="Group Category"
        selected={clientInformation?.groupCategory}
        setSelected={handleCategoryChange}
      />
      <TextField
        id="outlined-basic"
        label="Detail of the Group"
        variant="outlined"
        size="small"
        value={clientInformation?.groupDetail}
        onChange={(e) => setClientInformation({ ...clientInformation, groupDetail: e.target.value })}
      />
      <TextField
        id="outlined-basic"
        label="Age Limit"
        variant="outlined"
        type="number"
        size="small"
        min={18}
        max={100}
        InputProps={{
          inputProps: {
            min: 18,
            max: 100,
          },
        }}
        error={clientInformation?.ageLimit < 18 || clientInformation?.ageLimit > 100}
        helperText={
          (clientInformation?.ageLimit < 18 || clientInformation?.ageLimit > 100) &&
          'Age limit should be between 18 and 100'
        }
        value={clientInformation?.ageLimit}
        onChange={(e) => setClientInformation({ ...clientInformation, ageLimit: parseInt(e.target.value, 10) })}
      />
      <Dropdown
        options={typeOfRiskOptions}
        label="Type of Risk"
        selected={clientInformation?.typeOfRisk}
        setSelected={handleRiskChange}
      />
    </div>
  );
};

export default Tab1;
