const { default: useCreateQuote } = require('./quotesStore');

const useTab2Validation = () => {
  const clientInformation = useCreateQuote((state) => state.clientInformation);
  const volumes = useCreateQuote((state) => state.volumes);

  if (!volumes?.totalTravelDays) {
    return true;
  }
  if (!volumes?.participation) {
    return true;
  }
  if (!volumes?.maxTripDuration) {
    return true;
  }
  if (!volumes?.averageTripDuration) {
    return true;
  }
  if (!volumes?.intermediaryInformation) {
    return true;
  }
  if (!volumes?.brokerageInvolved) {
    return true;
  }
  if (clientInformation.businessType === 'rollover') {
    if (!volumes?.claimsPresentYear) {
      return true;
    }
    if (!volumes?.claimsLastYear) {
      return true;
    }
    if (!volumes?.claimsLastToLastYear) {
      return true;
    }
  }
  if (clientInformation.businessType === 'rollover') {
    if (!volumes?.premiumPresentYear) {
      return true;
    }
    if (!volumes?.premiumLastYear) {
      return true;
    }
    if (!volumes?.premiumLastToLastYear) {
      return true;
    }
  }

  return false;
};

export default useTab2Validation;
