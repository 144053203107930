import { Table, TableBody, TableRow, TableCell, Tab } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { allCoversDomestic, allCoversOverseas, groupCategoryOptions } from '../components/quotes/constants';

const RowItem = ({ label, value, isCustom }) => (
  <TableRow>
    <TableCell
      style={
        isCustom
          ? {
              textAlign: 'left !important',
              padding: '4px',
            }
          : {
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px',
              padding: '5px',
            }
      }
      colSpan={value ? undefined : 2}
    >
      {label}
    </TableCell>
    {value && (
      <TableCell
        style={{
          textAlign: 'center',
          fontSize: '12px',
          padding: '5px',
        }}
      >
        {value}
      </TableCell>
    )}
  </TableRow>
);

const GeneratedQuotePage = () => {
  const { quoteId } = useParams();

  const { data, isLoading, isError } = useQuery(['generatedQuote', quoteId], () =>
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/quote/download/${quoteId}`).then((res) => res.json())
  );

  console.log('data', data);

  const geography = data?.quoteData?.clientInformation?.geography || 'overseas';
  const isGeography = geography === 'overseas';
  const coverData = isGeography ? allCoversOverseas : allCoversDomestic;

  return (
    <div
      style={{
        width: '80%',
        margin: '0px auto',
        textAlign: 'center',
      }}
    >
      <Helmet>
        <title>Quote Sheet</title>
      </Helmet>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor: '#d0cece',
                padding: '5px',
              }}
              colSpan={2}
            >
              QUOTATION SHEET
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor: '#d0cece',
                padding: '5px',
              }}
              colSpan={2}
            >
              Overseas Travel Insurance
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
              }}
              colSpan={2}
            >
              This quote has been released on the basis of below mentioned details shared with us. Please inform us in
              case of any discrepancies and changes in claim details,as it has implications on appropriate pricing of
              the risk.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#d0cece',
              }}
            >
              Date
            </TableCell>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
              }}
            >
              Date
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#d0cece',
              }}
              colSpan={2}
            >
              Client Details
            </TableCell>
          </TableRow>
          <RowItem label="Name of the Client" value={data?.quoteData?.clientInformation?.clientName} />
          <RowItem label="Business Type" value={data?.quoteData?.clientInformation?.businessType} />
          <RowItem label="Industry Type" value={data?.quoteData?.clientInformation?.industryType} />
          <RowItem label="Location of the Client" value={data?.quoteData?.clientInformation?.location} />
          <RowItem label="Age Bracket" value={data?.quoteData?.clientInformation?.ageLimit} />
          <RowItem
            label="Group Category"
            value={
              groupCategoryOptions.find((item) => item.value === data?.quoteData?.clientInformation?.groupCategory)
                ?.label
            }
          />
          <RowItem label="Intermediary" value={'Intermediary'} />
          <RowItem
            label="Details of Group"
            value={
              data?.quoteData?.clientInformation?.groupCategory === 'employer_employee'
                ? `Employees of the master policy holder travelling overseas with accompanying spouse and children(6 months-18 years) on same trip.`
                : 'Customers of the Master policy holder purchasing travel packages from master policy holder'
            }
          />
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#d0cece',
              }}
              colSpan={2}
            >
              Covers Purchased
            </TableCell>
          </TableRow>
          {data?.quoteData?.clientInformation?.geography === 'domestic' ? (
            <TableRow>
              <TableCell />
              <TableCell>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="coverBodyTable">Premium</TableCell>
                      <TableCell className="coverBodyTable">Risk</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell className="coverBodyTable" />
              <TableCell
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '12px',
                  padding: '0px',
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className="coverBodyTable"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: '12px',
                          padding: '5px',
                          backgroundColor: '#d0cece',
                        }}
                      >
                        USA
                      </TableCell>
                      <TableCell
                        className="coverBodyTable"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: '12px',
                          padding: '5px',
                          backgroundColor: '#d0cece',
                        }}
                      >
                        NON-US
                      </TableCell>
                      <TableCell
                        className="coverBodyTable"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: '12px',
                          padding: '5px',
                          backgroundColor: '#d0cece',
                        }}
                      >
                        ASIA
                      </TableCell>
                      <TableCell
                        className="coverBodyTable"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: '12px',
                          padding: '5px',
                          backgroundColor: '#d0cece',
                        }}
                      >
                        Deductible
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          )}
          {Object.keys(data?.metadata || {}).map((key) => (
            <TableRow key={key}>
              <TableCell className="coverBodyTable">{coverData.find((item) => item.id === key)?.name}</TableCell>
              <TableCell className="coverBodyTable">
                <Table>
                  <TableBody>
                    {!isGeography ? (
                      <TableRow>
                        <TableCell className="coverBodyTable">
                          {data?.metadata[key].premium || (0.0).toFixed(2)}
                        </TableCell>
                        <TableCell className="coverBodyTable">{data?.metadata[key].deductible || 'Nil'}</TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell className="coverBodyTable">
                          {data?.metadata[key].premium || (0.0).toFixed(2)}
                        </TableCell>
                        <TableCell className="coverBodyTable">
                          {data?.metadata[key].premium || (0.0).toFixed(2)}
                        </TableCell>
                        <TableCell className="coverBodyTable">
                          {data?.metadata[key].premium || (0.0).toFixed(2)}
                        </TableCell>

                        <TableCell className="coverBodyTable">{data?.metadata[key].deductible || 'Nil'}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className="coverBodyTable">
              <b>Premium per person per day post GST</b>
            </TableCell>
            <TableCell className="coverBodyTable">
              <Table>
                <TableBody>
                  {!isGeography ? (
                    <TableRow>
                      <TableCell className="coverBodyTable">
                        <b> {data?.finalRates?.finalRateAsia.toFixed(2) || (0.0).toFixed(2)}</b>
                      </TableCell>
                      <TableCell className="coverBodyTable" />
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell className="coverBodyTable">
                        <b> {data?.finalRates?.finalRateUS.toFixed(2) || (0.0).toFixed(2)}</b>
                      </TableCell>
                      <TableCell className="coverBodyTable">
                        <b> {data?.finalRates?.finalRateNonUS.toFixed(2) || (0.0).toFixed(2)}</b>
                      </TableCell>
                      <TableCell className="coverBodyTable">
                        <b> {data?.finalRates?.finalRateAsia.toFixed(2) || (0.0).toFixed(2)}</b>
                      </TableCell>
                      <TableCell className="coverBodyTable" />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#d0cece',
              }}
              colSpan={2}
            >
              (For detailed T&C,Please refer the filed Policy Wording)
            </TableCell>
          </TableRow>
          <RowItem
            label="Any Policy/Certificate of Insurance are non refundable after the commencement of trip or if any claim has been lodged under such Policy/Certificate of insurance"
            value={''}
            isCustom
          />
          <RowItem
            label="Any claims arising out of Pre-existing disease and maternity are not covered"
            value={''}
            isCustom
          />
          <RowItem
            label="Insurance to be purchased along with flight ticket only and shall not be sold on standalone basis"
            value={''}
            isCustom
          />
          <RowItem
            label="Insurance to be available  only if end beneficiary travels in Air India flight booked through Air India's booking channel and not any other platform"
            value={''}
            isCustom
          />
          <RowItem
            label="Group Administrator to ensure that minimum 7 lives will be enrolled in policy"
            value={''}
            isCustom
          />
          <RowItem
            label="Group Administrator relationship is direct relationship with Group members"
            value={''}
            isCustom
          />{' '}
          <RowItem
            label="Reconciliation of travel days and premium to be done on a mutually agreeable periodic basis"
            value={''}
            isCustom
          />
          <RowItem label="Any change in GST/applicable taxes might change the premium	" value={''} isCustom />
          <RowItem label="No group should be formed for the purpose of insurance" value={''} isCustom />
          <RowItem
            label="One master policy for entire company with individual certificate per traveler"
            value={''}
            isCustom
          />
          <RowItem
            label="Attested copy of this Quote Letter details will be required for binding this policy."
            value={''}
            isCustom
          />
          <TableCell
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px',
              padding: '5px',
              backgroundColor: '#d0cece',
            }}
            colSpan={2}
          >
            Major Exclusions*
          </TableCell>
          <RowItem
            label={`Where the Insured Person is travelling against the advice of a Medical Practitioner; or receiving or on a waiting list for receiving specified medical treatment; 
or is travelling for the purpose of obtaining treatment; or has received a terminal prognosis for a medical condition.`}
            isCustom
          />
          <RowItem
            label={`Treatment which could be reasonably delayed until the Insured Person's return to place of destination.`}
            isCustom
          />
          <RowItem
            label={`Any Claim occurring in a geographic zone/city/state which is not specifically covered under the Policy and is not mentioned in the Policy Schedule/ Certificate of Insurance`}
            isCustom
          />
          <RowItem
            label={`Suicide, attempted suicide (whether sane or insane) or intentionally self-inflicted Injury or Illness.`}
            isCustom
          />
          <RowItem
            label={
              'Certification / diagnosis / Treatment by a family member, or a person who stays with the Insured Person, or from persons not registered as Medical Practitioners under the respective Medical Councils, or from a Medical Practitioner who is practicing outside the discipline that he is licensed for, or any diagnosis.'
            }
            isCustom
          />
          <RowItem
            label={
              'Treatment that is not scientifically recognised or Unproven/Experimental treatment, or any form of clinical trials or any kind of self-medication and its complications.'
            }
            isCustom
          />
          <RowItem
            label={'Rest or recuperation at a spa or health resort, sanatorium, convalescence home or institution.'}
            isCustom
          />
          <RowItem
            label={
              'Any form of Alternative Treatment: AYUSH Treatment; Hydrotherapy, Acupuncture, Reflexology Treatment or any other form of indigenous system of medicine.'
            }
            isCustom
          />
          <RowItem
            label={
              'Any expenses incurred in connection to Treatment for general debility, ageing, convalescence, sanatorium Treatment, rehabilitation measures, private duty nursing, respite care, health resort, rundown condition or rest cure.'
            }
            isCustom
          />
          <RowItem
            label="Circumcision unless necessary for Treatment of an Illness or Injury not excluded hereunder or due to an Accident."
            isCustom
          />
          <RowItem
            label={`Associated expenses for alopecia, baldness, wigs, or toupees and hair fall treatment and products, issue of medical certificates and examinations as to 
suitability for employment or travel.				
						`}
            isCustom
          />
          <RowItem
            label={`Any injury, Accident, illness or any other loss sustained or contracted or caused by the Insured Person as a consequence of the Insured Person being intoxicated or being under the influence of any narcotic or drug or alcohol.`}
            isCustom
          />
          <RowItem
            label={`Participation in an actual or attempted felony, riot, crime, misdemeanor, or civil commotion.`}
            isCustom
          />
          <RowItem
            label={`Operating or learning to operate any aircraft or performing duties as a member of the crew on any aircraft or Scheduled Airline.`}
            isCustom
          />
          <RowItem
            label={`Any loss due to the release, dispersal or application of pathogenic or poisonous biological or chemical materials.`}
            isCustom
          />
          <RowItem
            label={`External Congenital anomalies or any complications or conditions arising there from.`}
            isCustom
          />
          <RowItem
            label={`Any Insured Person’s participation in Adventure sports without expert supervision of trained professional or participation in Professional Sports.`}
            isCustom
          />
          <RowItem
            label={`Insured Person’s actual or willful participation in, an illegal act or any violation or attempted violation of the law.`}
            isCustom
          />
          <RowItem
            label={`Any loss caused by osteoporosis (porosity and brittleness of the bones due to loss of protein form the bones matrix) or pathological fracture (any fracture in  an area where Pre-existing Disease has caused the weakening of the bone) if osteoporosis or bone Disease diagnosed prior to the Policy / Cover Period.`}
            isCustom
          />
          <RowItem label={`Confinement in a Hospital which is not medically necessary.`} isCustom />
          <RowItem
            label={`In respect of travel by the Insured Person to any places against whom the Republic of India has imposed general or special travel restrictions, or against whom it may be imposed such restrictions.`}
            isCustom
          />
          <RowItem label={`Pre-existing Disease or its complications and Maternity or its Compilations.`} isCustom />
          <RowItem
            label={`Insured Person's Participation in Adventure Sports for Leisure performed under expert supervision of trained professional.`}
            isCustom
          />
          <RowItem
            label={`The radioactive, toxic, explosive or other dangerous properties of any explosive nuclear equipment or any part of that equipment.`}
            isCustom
          />
          <RowItem
            label={`Ionising radiation or contamination by radioactivity from any nuclear fuel or from any nuclear waste from burning nuclear fuel.`}
            isCustom
          />
          <RowItem
            label={`Losses due to war or any act of war, invasion, act of foreign enemy, civil war, public defense, rebellion, revolution, insurrection, military or usurped acts, nuclear weapons/materials, Chemical and biological weapons, Ionizing radiation.						`}
            isCustom
          />
          <RowItem
            label={`Servicing in OFAC sanction countries will be on reimbursement basis. No assistance services will be provided in OFAC sanction countries`}
            isCustom
          />
          <br />
          <RowItem label={''} />
          <RowItem
            label={`1. Prohibition of Rebates – Section 41 of the Insurance Act, 1938 as amended by Insurance Laws (Amendment) Act 2015. No person shall allow or offer to allow, either directly or indirectly, as an inducement to any person to take out or renew or continue an insurance in respect of any kind of risk relating to lives or property in India, any rebate of the whole or part of the commission payable or any rebate of premium shown on the policy, nor shall any person taking out or renewing or continuing a policy accept any rebate, except such rebate as may be allowed in accordance with the prospectus or tables of the insurer.2. Any person making default in complying with the provision of this section shall be liable for penalty which may extend to ten lakh rupees. 						`}
            isCustom
          />
          <br />
          <RowItem label={''} />
          <RowItem
            label={`2.AML guidelines

A. I/we hereby confirm that all premiums have been/will be paid from bonafide sources and no premiums have been/will be paid out of proceeds of crime related to any of the offence listed in prevention of Money Laundering Act, 2002. • I understand that the Company has the right to call for documents to establish sources of funds• The insurance company has right to cancel the insurance contract in case I am/have been found guilty by any competent court of law under any of the statutes, directly or indirectly governing the prevention of money laundering in India.5. Insurance is the subject matter of the solicitation. For more details on risk factors, terms and conditions, please read sales
brochure/policy wordings carefully, before concluding a sale. 

B. We understand that as per Master Guidelines on Anti-Money Laundering/ Counter Financing of Terrorism (AML/CFT), 2022 (Ref: IRDAI/IID/GDL/MISC/160/8/2022) issued by IRDAI, the Master Policyholders under the group insurance shall maintain the details of all the individual members covered, which shall also be made available to the insurer as and when required.`}
            isCustom
          />
          <RowItem label="This Quote is valid until" value={new Date().toISOString().split('T')[0]} />
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#d0cece',
              }}
              colSpan={2}
            >
              Declaration
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
              }}
              colSpan={2}
            >
              We are pleased to accept your quotation as also terms & conditions listed above.
            </TableCell>
          </TableRow>
          <br />
          <br />
          <br />
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
              }}
            >
              Authorized Signatory with Company Stamp / Seal
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
              }}
            >
              Date
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default GeneratedQuotePage;
