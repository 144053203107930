export const lobOptions = [
  {
    value: 'health_insurance',
    label: 'Health Insurance',
  },
  {
    value: 'personal_accident_insurance',
    label: 'Personal Accident Insurance',
  },
  {
    value: 'travel_insurance',
    label: 'Travel Insurance',
  },
];

export const businessTypeOptions = [
  {
    value: 'fresh',
    label: 'Fresh',
  },
  {
    value: 'rollover',
    label: 'Rollover',
  },
];

export const geographyOptions = [
  {
    value: 'overseas',
    label: 'Overseas',
  },
  {
    value: 'domestic',
    label: 'Domestic',
  },
];

export const groupCategoryOptions = [
  {
    value: 'employer_employee',
    label: 'Employer Employee',
  },
  {
    value: 'non_employer_employee',
    label: 'Non Employer Employee',
  },
];

export const typeOfRiskOptions = [
  {
    value: 'employee',
    label: 'Employee',
  },
  {
    value: 'employee_spouse_children',
    label: 'Employee, Spouse & Children',
  },
];

export const maxTripDuration = [
  {
    value: '7',
    label: '7',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '60',
    label: '60',
  },
  {
    value: '120',
    label: '120',
  },
  {
    value: '180',
    label: '180',
  },
  {
    value: '360',
    label: '360',
  },
];

export const allCoversOverseas = [
  {
    name: 'Medical Expenses-Accident and Illness',
    id: 'medical_expenses_accident_and_illness',
    minValue: 0,
    maxValue: 500000,
    showDeductible: true,
    type: 'mandatory',
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '25',
        label: '25',
      },
      {
        value: '50',
        label: '50',
      },
      {
        value: '75',
        label: '75',
      },
      {
        value: '100',
        label: '100',
      },
      {
        value: '150',
        label: '150',
      },
      {
        value: '200',
        label: '200',
      },
    ],
  },
  {
    name: 'Medical Expenses Accident Only',
    id: 'medical_expenses_accident_only',
    minValue: 0,
    maxValue: 500000,
    showDeductible: true,
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '25',
        label: '25',
      },
      {
        value: '50',
        label: '50',
      },
      {
        value: '75',
        label: '75',
      },
      {
        value: '100',
        label: '100',
      },
      {
        value: '150',
        label: '150',
      },
      {
        value: '200',
        label: '200',
      },
    ],
  },
  {
    name: 'Accidental Death-24 hours',
    id: 'accidental_death',
    minValue: 1000,
    maxValue: 50000,
    showDeductible: false,
    type: 'mandatory',
  },
  {
    name: 'Hospital Daily Cash',
    id: 'hospital_daily_cash',
    minValue: 0,
    maxValue: 10000,
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1',
        label: '1',
      },
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4',
        label: '4',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '6',
        label: '6',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '8',
        label: '8',
      },
    ],
    showDeductible: true,
    endAddon: 'days',
    type: 'mandatory',
  },
  {
    name: 'Outpatient Expenses-Accident and Illness',
    id: 'outpatient_expenses_accident_and_illness',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Outpatient Expense-Accident only',
    id: 'outpatient_expense_accident_only',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Permanent Total Disability',
    id: 'permanent_total_disability',
    minValue: 0,
    maxValue: 50000,
    showDeductible: false,
  },
  {
    name: 'Permanent Partial Disability',
    id: 'permanent_partial_disability',
    minValue: 0,
    maxValue: 50000,
    showDeductible: false,
  },
  {
    name: 'Trip Cancellation',
    id: 'trip_cancellation',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Trip Interruption',
    id: 'trip_interruption',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Accommodation Cancellation',
    id: 'accommodation_cancellation',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Common Carrier Delay',
    id: 'common_carrier_delay',
    minValue: 0,
    maxValue: 2500,
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4',
        label: '4',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '6',
        label: '6',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '8',
        label: '8',
      },
      {
        value: '9',
        label: '9',
      },
      {
        value: '10',
        label: '10',
      },
      {
        value: '11',
        label: '11',
      },
      {
        value: '12',
        label: '12',
      },
    ],
    showDeductible: true,
    endAddon: 'hours',
  },
  {
    name: 'Loss of Checked in baggage',
    id: 'loss_of_checked_in_baggage',
    minValue: 0,
    maxValue: 2000,
    showDeductible: true,
  },
  {
    name: 'Delay of Checked in baggage',
    id: 'delay_of_checked_in_baggage',
    minValue: 0,
    maxValue: 2000,
    typeDeductible: 'dropdown',
    endAddon: 'hours',
    labels: [
      {
        value: '2',
        label: '2',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '12',
        label: '12',
      },
    ],
    showDeductible: true,
  },
  {
    name: 'Missed Connection',
    id: 'missed_connection',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Missed Flight/Cruise',
    id: 'missed_flight_cruise',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Compassionate Visit',
    id: 'compassionate_visit',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Replacement and Rearrangement of Staff',
    id: 'replacement_and_rearrangement_of_staff',
    minValue: 0,
    maxValue: 10000,
    showDeductible: true,
  },
  {
    name: 'Loss of Passport',
    id: 'loss_of_passport',
    minValue: 0,
    maxValue: 5000,
    showDeductible: true,
  },
  {
    name: 'Loss of Bagggae and Personal Effects',
    id: 'loss_of_bagggae_and_personal_effects',
    minValue: 0,
    maxValue: 5000,
    showDeductible: true,
  },
  {
    name: 'Adventure Sports Cover',
    id: 'adventure_sports_cover',
    typeSumAssurance: 'dropdown',
    typeDeductible: 'dropdown',
    labels: [
      {
        value: 'included',
        label: 'Included',
      },
      {
        value: 'excluded',
        label: 'Excluded',
      },
    ],
    showDeductible: false,
  },
];

export const allCoversDomestic = [
  {
    name: 'Medical Expenses-Accident and Illness',
    id: 'medical_expenses_accident_and_illness',
    minValue: 0,
    maxValue: 1000000,
    showDeductible: true,
    type: 'mandatory',
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '100',
        label: '100',
      },
      {
        value: '250',
        label: '250',
      },
      {
        value: '500',
        label: '500',
      },
      {
        value: '1000',
        label: '1000',
      },
      {
        value: '2000',
        label: '2000',
      },
      {
        value: '3000',
        label: '3000',
      },
    ],
  },
  {
    name: 'Medical Expenses Accident Only',
    id: 'medical_expenses_accident_only',
    minValue: 0,
    maxValue: 1000000,
    showDeductible: true,
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '100',
        label: '100',
      },
      {
        value: '250',
        label: '250',
      },
      {
        value: '500',
        label: '500',
      },
      {
        value: '1000',
        label: '1000',
      },
      {
        value: '2000',
        label: '2000',
      },
      {
        value: '3000',
        label: '3000',
      },
    ],
  },
  {
    name: 'Accidental Death-24 hours',
    id: 'accidental_death',
    minValue: 10000,
    maxValue: 1000000,
    showDeductible: false,
    type: 'mandatory',
  },
  {
    name: 'Hospital Daily Cash',
    id: 'hospital_daily_cash',
    minValue: 0,
    maxValue: 25000,
    typeDeductible: 'dropdown',
    type: 'mandatory',
    labels: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1',
        label: '1',
      },
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4',
        label: '4',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '6',
        label: '6',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '8',
        label: '8',
      },
    ],
    showDeductible: true,
    endAddon: 'days',
  },
  {
    name: 'Outpatient Expenses-Accident and Illness',
    id: 'outpatient_expenses_accident_and_illness',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Outpatient Expense-Accident only',
    id: 'outpatient_expense_accident_only',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Permanent Total Disability',
    id: 'permanent_total_disability',
    minValue: 10000,
    maxValue: 1000000,
    showDeductible: false,
  },
  {
    name: 'Permanent Partial Disability',
    id: 'permanent_partial_disability',
    minValue: 10000,
    maxValue: 1000000,
    showDeductible: false,
  },
  {
    name: 'Trip Cancellation',
    id: 'trip_cancellation',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Trip Interruption',
    id: 'trip_interruption',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Accomoodation Cancellation',
    id: 'accomoodation_cancellation',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Common Carrier Delay',
    id: 'common_carrier_delay',
    minValue: 0,
    maxValue: 10000,
    typeDeductible: 'dropdown',
    labels: [
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4',
        label: '4',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '6',
        label: '6',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '8',
        label: '8',
      },
      {
        value: '9',
        label: '9',
      },
      {
        value: '10',
        label: '10',
      },
      {
        value: '11',
        label: '11',
      },
      {
        value: '12',
        label: '12',
      },
    ],
    showDeductible: true,
    endAddon: 'hours',
  },
  {
    name: 'Loss of Checked in baggage',
    id: 'loss_of_checked_in_baggage',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Delay of Checked in baggage',
    id: 'delay_of_checked_in_baggage',
    minValue: 0,
    maxValue: 5000,
    typeDeductible: 'dropdown',
    endAddon: 'hours',
    labels: [
      {
        value: '2',
        label: '2',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '12',
        label: '12',
      },
    ],
    showDeductible: true,
  },
  {
    name: 'Missed Connection',
    id: 'missed_connection',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Missed Flight/Cruise',
    id: 'missed_flight_cruise',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Compassionate Visit',
    id: 'compassionate_visit',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Replacement and Rearrangement of Staff',
    id: 'replacement_and_rearrangement_of_staff',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Loss of baggage and Personal Effects',
    id: 'loss_of_baggage_and_personal_effects',
    minValue: 0,
    maxValue: 50000,
    showDeductible: true,
  },
  {
    name: 'Adventure Sports Cover',
    id: 'adventure_sports_cover',
    typeSumAssurance: 'dropdown',
    typeDeductible: 'dropdown',
    labels: [
      {
        value: 'included',
        label: 'Included',
      },
      {
        value: 'excluded',
        label: 'Excluded',
      },
    ],
    showDeductible: false,
  },
];
