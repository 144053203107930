import { create } from 'zustand';

const useCreateQuote = create((set) => ({
  clientInformation: {},
  setClientInformation: (clientInformation) => set({ clientInformation }),

  volumes: {},
  setVolumes: (volumes) => set({ volumes }),

  coversInformation: [],
  setCoversInformation: (coversInformation) => set({ coversInformation }),
}));

export default useCreateQuote;
