import axios from 'axios';
import { useQuery } from 'react-query';
import { getToken } from '../utils/localStorage';

const useQuotes = (limit, page, type, all = false) => {
  const { data, isLoading, isFetching, refetch } = useQuery(['quotes', limit, page, type], async () => {
    const _data = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/quote`, {
      params: {
        limit,
        page,
        type: type === 'all' ? '' : type,
        all,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return _data.data;
  });
  return { data, isLoading, isFetching, refetch };
};

export default useQuotes;
