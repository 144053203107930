export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getUserData = () => {
  const user = getLocalStorage('user');
  return user || {};
};

export const getToken = () => {
  const { token } = getUserData()?.tokens?.access;
  return token || null;
};
