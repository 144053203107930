import axios from 'axios';
import { useQuery } from 'react-query';
import { getToken } from '../utils/localStorage';

const useExistingUser = (id) => {
  const { data, isLoading, isFetching } = useQuery(
    ['users-existing', id],
    async () => {
      const _data = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return _data.data;
    },
    {
      enabled: !!id,
    }
  );
  return { data, isLoading, isFetching };
};

export default useExistingUser;
