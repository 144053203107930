import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import QuotePage from './pages/QuotePage';
import NewQuotePage from './pages/NewQuote';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import NewUser from './pages/NewUser';
import UserTree from './pages/UserTree';
import GeneratedQuotePage from './pages/GeneratedQuotePage';
import { getUserData } from './utils/localStorage';

// ----------------------------------------------------------------------

const authenticatedRoutes = [
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },

      { path: 'app', element: <DashboardAppPage /> },
      { path: 'user', element: <UserPage /> },
      { path: 'products', element: <ProductsPage /> },
      { path: 'blog', element: <BlogPage /> },
      {
        path: 'new/quote',
        element: <NewQuotePage />,
      },
      {
        path: 'quote',
        element: <QuotePage />,
      },
      {
        path: 'user/new',
        element: <NewUser />,
      },
      {
        path: 'user/tree',
        element: <UserTree />,
      },
    ],
  },
  {
    path: '/generated/quote/:quoteId',
    element: <GeneratedQuotePage />,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
];

const unAuthenticatedRoutes = [
  {
    path: '*',
    element: <LoginPage />,
  },
];

export default function Router() {
  const user = getUserData();
  const routes = useRoutes(Object.keys(user).length ? authenticatedRoutes : unAuthenticatedRoutes);

  // if (!user) {
  //   return <Navigate to="/login" />;
  // }

  return routes;
}
