import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { Cancel } from '@mui/icons-material';
import { allCoversOverseas, allCoversDomestic } from './constants';
import useCreateQuote from './quotesStore';
import Dropdown from './dropdown';

export default function Tab3() {
  const clientInformation = useCreateQuote((state) => state.clientInformation);
  const coversInformation = useCreateQuote((state) => state.coversInformation);
  const setCoversInformation = useCreateQuote((state) => state.setCoversInformation);

  React.useEffect(() => {
    if (clientInformation?.geography === 'domestic') {
      setCoversInformation(allCoversDomestic.filter((cover) => cover.type === 'mandatory'));
    } else {
      setCoversInformation(allCoversOverseas.filter((cover) => cover.type === 'mandatory'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInformation]);

  const handleSumInsuredChange = (id, value) => {
    const newSelectedCovers = coversInformation.map((cover) => {
      if (cover.id === id) {
        return {
          ...cover,
          sumInsured: value,
        };
      }
      return cover;
    });
    setCoversInformation(newSelectedCovers);
  };

  const handleDeductibleChange = (id, value) => {
    const newSelectedCovers = coversInformation.map((cover) => {
      if (cover.id === id) {
        return {
          ...cover,
          deductible: value,
        };
      }
      return cover;
    });
    setCoversInformation(newSelectedCovers);
  };

  const handleChange = (_event, value) => {
    if (value) {
      setCoversInformation([...coversInformation, { ...value, sumInsured: 0, deductible: 0 }]);
    }
  };

  const handleClose = (id) => {
    setCoversInformation(coversInformation.filter((cover) => cover.id !== id));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Covers</TableCell>
            <TableCell align="left">Sum Insured</TableCell>
            <TableCell align="left"> Deductible</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coversInformation.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">
                {row.typeSumAssurance === 'dropdown' ? (
                  <Dropdown
                    options={row.labels}
                    label="Sum Insured"
                    selected={row.sumInsured}
                    setSelected={(val) => handleSumInsuredChange(row.id, val)}
                  />
                ) : (
                  <TextField
                    id="outlined-basic"
                    label="Sum Insured"
                    variant="outlined"
                    type="number"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {clientInformation?.geography === 'domestic' ? '₹' : '$'}
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: row.minValue,
                        max: row.maxValue,
                      },
                    }}
                    error={row.sumInsured < row.minValue || row.sumInsured > row.maxValue}
                    helperText={
                      row.sumInsured < row.minValue || row.sumInsured > row.maxValue
                        ? `Sum Insured should be between ${row.minValue} and ${row.maxValue}`
                        : ''
                    }
                    size="small"
                    onChange={(e) => handleSumInsuredChange(row.id, parseInt(e.target.value, 10))}
                    value={row.sumInsured}
                  />
                )}
              </TableCell>
              <TableCell align="right">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    flex: 1,
                  }}
                >
                  {row.showDeductible && (
                    <>
                      {row.typeDeductible === 'dropdown' ? (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            width: '100%',
                          }}
                        >
                          <Dropdown
                            options={row.labels}
                            label="Deductible"
                            selected={row.deductible}
                            setSelected={(val) => handleDeductibleChange(row.id, parseInt(val, 10))}
                            style={{ width: '100%' }}
                            endAddon={row.endAddon}
                          />
                        </div>
                      ) : (
                        <TextField
                          id="outlined-basic"
                          label="Deductible"
                          variant="outlined"
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{row.endAddon}</InputAdornment>,
                          }}
                          size="small"
                          onChange={(e) => handleDeductibleChange(row.id, parseInt(e.target.value, 10))}
                          value={row.deductible}
                          fullWidth
                        />
                      )}
                    </>
                  )}
                  {row.type !== 'mandatory' && (
                    <Cancel
                      style={{
                        fill: 'red',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClose(row.id)}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ minWidth: 120, zIndex: 1000 }}>
        <Autocomplete
          id="combo-box-demo"
          options={(clientInformation.geography === 'domestic' ? allCoversDomestic : allCoversOverseas).filter(
            (cover) => !coversInformation.find((selectedCover) => selectedCover.id === cover.id)
          )}
          renderInput={(params) => <TextField value={''} {...params} label="Select Cover" />}
          getOptionLabel={(option) => option.name}
          onChange={handleChange}
          size="small"
        />
      </Box>
    </TableContainer>
  );
}
