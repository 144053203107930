import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tab,
  Box,
} from '@mui/material';
// components
import axios from 'axios';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { redirect, useNavigate } from 'react-router-dom';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import useUsers from '../hooks/useUsers';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'city', label: 'Branch Location', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const getColor = (role) => {
  switch (role) {
    case 'admin':
      return 'error';
    case 'user':
      return 'info';
    case 'manager':
      return 'warning';
    default:
      return 'success';
  }
};

const TabData = ({
  selected,
  filterName,
  handleFilterByName,
  order,
  orderBy,
  handleChangePage,
  data,
  handleChangeRowsPerPage,
  handleClick,
  handleRequestSort,
  handleSelectAllClick,
  handleOpenMenu,
  isNotFound,
  emptyRows,
  rowsPerPage,
  page,
}) => (
  <Card>
    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table size="small">
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={data?.results.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {data?.results.map((row) => {
              const { id, name, role, city, email, company, avatarUrl, isVerified } = row;
              const selectedUser = selected.indexOf(id) !== -1;

              return (
                <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar alt={name} src={avatarUrl} />
                      <Typography variant="subtitle2" noWrap>
                        {name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">{city}</TableCell>
                  <TableCell align="left">{company}</TableCell>

                  <TableCell align="left">
                    <Label color={getColor(role)}>{sentenceCase(role)}</Label>
                  </TableCell>

                  {/* <TableCell align="left">
                    <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                  </TableCell> */}
                  <TableCell align="left">{email}</TableCell>
                  <TableCell align="right">
                    <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                      <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>

          {isNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Not found
                    </Typography>

                    <Typography variant="body2">
                      No results found for &nbsp;
                      <strong>&quot;{filterName}&quot;</strong>.
                      <br /> Try checking for typos or using complete words.
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>

    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={data?.totalResults}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Card>
);

export default function UserPage() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.results.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const [value, setValue] = useState('1');
  const [type, setType] = useState('all');
  const { data } = useUsers(rowsPerPage, page + 1, type);

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - (data?.results?.length || 0)) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(event.target.innerText.toLowerCase().split(' ').join(''));
  };

  return (
    <>
      <Helmet>
        <title> User</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              console.log('clicked');
              return navigate('/dashboard/user/new');
            }}
          >
            New User
          </Button>
        </Stack>

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="1" />
              <Tab label="Admin" value="2" />
              <Tab label="Manager" value="3" />
              <Tab label="Under Writer" value="4" />
              <Tab label="User" value="5" />
            </TabList>
          </Box>
          {['1', '2', '3', '4', '5'].map((item) => (
            <TabPanel value={item} key={item}>
              <TabData
                selected={selected}
                filterName={filterName}
                handleFilterByName={handleFilterByName}
                order={order}
                orderBy={orderBy}
                handleChangePage={handleChangePage}
                data={data}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleClick={handleClick}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                handleOpenMenu={handleOpenMenu}
                isNotFound={isNotFound}
                emptyRows={emptyRows}
                rowsPerPage={rowsPerPage}
                page={page}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
