import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Snackbar, TextField } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import useExistingUser from '../hooks/useExistingUser';
import Dropdown from '../components/quotes/dropdown';
import useUsers from '../hooks/useUsers';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const managerOption = {
  label: 'Manager',
  value: 'manager',
};

const AdminOption = {
  label: 'Admin',
  value: 'admin',
};

const UnderwriterOption = {
  label: 'Underwriter',
  value: 'underwriter',
};

const UserOption = {
  label: 'User',
  value: 'user',
};

const NewUser = () => {
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [role, setRole] = React.useState('');
  const [error, setError] = React.useState('');
  const [user, setUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const query = useQuery();
  const [isEditMode, setIsEditMode] = useState(false);

  const id = query.get('id');
  const { data: existingData } = useExistingUser(id);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
    }
  }, [id]);

  useEffect(() => {
    console.log('existingData', existingData);
    if (!existingData) return;
    setName(existingData.name);
    setEmail(existingData.email);
    setPhoneNumber(existingData.phoneNumber);
    setCountry(existingData.country);
    setState(existingData.state);
    setCity(existingData.city);
    setAddress(existingData.address);
    setZipCode(existingData.zipCode);
    setCompany(existingData.company);
    setRole(existingData.role);
    if (existingData.admins?.length) {
      setSelectedUser(existingData.admins[0]);
    }
  }, [existingData]);

  const getUserMaster = () => {
    if (role === 'admin') {
      return 'Owner';
    }
    if (role === 'manager') {
      return 'Admin';
    }
    if (role === 'underwriter') {
      return 'Manager';
    }
    if (role === 'user') {
      return 'Under Writer';
    }
    return '';
  };

  const { data } = useUsers(null, null, getUserMaster().toLowerCase().split(' ').join(''), true);

  const resetState = (res) => {
    setUser(res.data);
    setName('');
    setEmail('');
    setPhoneNumber('');
    setCountry('');
    setState('');
    setCity('');
    setAddress('');
    setZipCode('');
    setCompany('');
    setRole('');
    setError('');
    setSelectedUser(null);
  };

  const handleUSer = async () => {
    const body = {
      name,
      email,
      phoneNumber,
      country,
      state,
      city,
      address,
      zipCode,
      company,
      role,
      password: 'password1234',
    };
    if (role === 'manager') {
      body.admins = [selectedUser];
    }
    if (role === 'underwriter') {
      body.managers = [selectedUser];
    }
    if (role === 'user') {
      body.underwriters = [selectedUser];
    }

    if (isEditMode) {
      await axios
        .patch(`${process.env.REACT_APP_BASE_URL}/v1/users/${id}`, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokens?.access?.token}` || '',
          },
        })
        .then((res) => {
          resetState(res);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response.data.message);
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/users`, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokens?.access?.token}` || '',
          },
        })
        .then((res) => {
          resetState(res);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    const { role } = JSON.parse(localStorage.getItem('user')).user;
    if (role === 'owner') {
      setRoleOptions([AdminOption, managerOption, UnderwriterOption, UserOption]);
    } else if (role === 'admin') {
      setRoleOptions([UnderwriterOption, UserOption, managerOption]);
    } else if (role === 'manager') {
      setRoleOptions([UnderwriterOption, UserOption]);
    } else if (role === 'underwriter') {
      setRoleOptions([UserOption]);
    }
  }, []);

  return (
    <div>
      <h1>{isEditMode ? 'Edit Existing' : 'Create New'} User</h1>
      <Snackbar open={user} message={'User Created Successfully'} />
      <Card>
        {error && <Alert severity="error">{error}</Alert>}
        <div
          style={{
            padding: '20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '20px',
          }}
        >
          <TextField
            label="Full Name"
            size="medium"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            size="medium"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isEditMode}
          />
          <TextField
            label="Phone Number"
            size="medium"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            label="Country"
            size="medium"
            variant="outlined"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <TextField
            label="State/Region"
            size="medium"
            variant="outlined"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <TextField
            label="City"
            size="medium"
            variant="outlined"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            label="Address"
            size="medium"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            label="Zip/Code"
            size="medium"
            variant="outlined"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <TextField
            label="Company"
            size="medium"
            variant="outlined"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <Dropdown
            options={roleOptions}
            label={'Role'}
            size="medium"
            setSelected={setRole}
            selected={role}
            disabled={isEditMode}
          />
          {(role === 'manager' || role === 'user' || role === 'underwriter') && (
            <Dropdown
              options={data?.results?.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
              label={`Select ${getUserMaster()} for the User`}
              size="medium"
              setSelected={setSelectedUser}
              selected={selectedUser}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            style={{
              margin: '0px 0px 20px',
            }}
            onClick={() => {
              navigate('/dashboard/user');
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            style={{
              margin: '0px 20px 20px',
            }}
            onClick={handleUSer}
          >
            {isEditMode ? 'Edit User' : 'Create User'}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default NewUser;
