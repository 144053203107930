import React from 'react';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Dropdown from './dropdown';
import useCreateQuote from './quotesStore';
import { maxTripDuration } from './constants';

const Tab2 = () => {
  const clientInformation = useCreateQuote((state) => state.clientInformation);
  const volumes = useCreateQuote((state) => state.volumes);
  const setVolumes = useCreateQuote((state) => state.setVolumes);

  const handleMaxTripDurationChange = (data) => {
    setVolumes({ ...volumes, maxTripDuration: parseInt(data, 10) });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Typography my={2} variant={'h6'}>
          UW Parameters
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
          }}
        >
          <TextField
            id="outlined-basic"
            label="Total Travel Days"
            variant="outlined"
            type="number"
            size="small"
            value={volumes?.totalTravelDays}
            onChange={(e) => setVolumes({ ...volumes, totalTravelDays: parseInt(e.target.value, 10) })}
            error={volumes?.totalTravelDays < 100 || volumes?.totalTravelDays > 50000}
            helperText={
              (volumes?.totalTravelDays < 100 || volumes?.totalTravelDays > 50000) &&
              'Total Travel Days should be between 100 and 50000'
            }
          />
          <TextField
            id="outlined-basic"
            label="Participation"
            variant="outlined"
            type="number"
            size="small"
            value={volumes?.participation}
            onChange={(e) => setVolumes({ ...volumes, participation: parseInt(e.target.value, 10) })}
            error={volumes?.participation <= 0 || volumes?.participation > 100}
            helperText={
              (volumes?.participation <= 0 || volumes?.participation > 100) &&
              'Participation should be between 0 and 100'
            }
            InputProps={{
              endAdornment: <span>%</span>,
              inputProps: {
                min: 1,
                max: 100,
              },
            }}
          />
          <Dropdown
            options={maxTripDuration}
            label="Maximum Trip Duration"
            selected={volumes?.maxTripDuration}
            setSelected={handleMaxTripDurationChange}
          />
          <TextField
            id="outlined-basic"
            label="Average Trip Duration"
            variant="outlined"
            type="number"
            size="small"
            value={volumes?.averageTripDuration}
            onChange={(e) => setVolumes({ ...volumes, averageTripDuration: parseInt(e.target.value, 10) })}
            error={
              clientInformation?.geography === 'domestic'
                ? volumes?.averageTripDuration < 1 || volumes?.averageTripDuration > 15
                : volumes?.averageTripDuration < 7 || volumes?.averageTripDuration > 120
            }
            helperText={
              clientInformation?.geography === 'domestic'
                ? (volumes?.averageTripDuration < 1 || volumes?.averageTripDuration > 15) &&
                  'Average Trip Duration should be between 1 and 15'
                : (volumes?.averageTripDuration < 7 || volumes?.averageTripDuration > 120) &&
                  'Average Trip Duration should be between 7 and 120'
            }
            InputProps={{
              inputProps: {
                min: clientInformation?.geography === 'domestic' ? 1 : 7,
                max: clientInformation?.geography === 'domestic' ? 15 : 120,
              },
            }}
          />
        </div>
      </div>
      <div>
        <Typography my={2} variant={'h6'}>
          Intermediary Information
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
          }}
        >
          <TextField
            id="outlined-basic"
            label="Intermediary Information"
            variant="outlined"
            size="small"
            value={volumes?.intermediaryInformation}
            onChange={(e) => setVolumes({ ...volumes, intermediaryInformation: e.target.value })}
          />
          <TextField
            id="outlined-basic"
            label="Brokerage Involved(%)"
            variant="outlined"
            type="number"
            size="small"
            value={volumes?.brokerageInvolved}
            onChange={(e) => setVolumes({ ...volumes, brokerageInvolved: parseInt(e.target.value, 10) })}
            error={
              clientInformation?.groupCategory === 'employer_employee'
                ? volumes?.brokerageInvolved < 0 || volumes?.brokerageInvolved > 7.5
                : volumes?.brokerageInvolved < 0 || volumes?.brokerageInvolved > 15
            }
            helperText={
              clientInformation?.groupCategory === 'employer_employee'
                ? (volumes?.brokerageInvolved < 0 || volumes?.brokerageInvolved > 7.5) &&
                  'Brokerage Involved should be between 0 and 7.5'
                : (volumes?.brokerageInvolved < 0 || volumes?.brokerageInvolved > 15) &&
                  'Brokerage Involved should be between 0 and 15'
            }
            InputProps={{
              inputProps: {
                min: clientInformation?.groupCategory === 'employer_employee' ? 0 : 7.5,
                max: clientInformation?.groupCategory === 'employer_employee' ? 0 : 15,
              },
              endAdornment: <span>%</span>,
            }}
          />
          <TextField
            id="outlined-basic"
            label="Additional Loading"
            variant="outlined"
            type="number"
            size="small"
            value={volumes?.additionalLoading}
            onChange={(e) => setVolumes({ ...volumes, additionalLoading: parseInt(e.target.value, 10) })}
            InputProps={{
              inputProps: {
                min: 0,
                max: 50,
              },
              endAdornment: <span>%</span>,
            }}
            error={volumes?.additionalLoading < 0 || volumes?.additionalLoading > 50}
            helperText={
              (volumes?.additionalLoading < 0 || volumes?.additionalLoading > 50) &&
              'Additional Loading should be between 0 and 50'
            }
          />
        </div>
      </div>
      {clientInformation.businessType === 'rollover' && (
        <div>
          <Typography my={2} variant={'h6'}>
            Claims Details
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '10px',
            }}
          >
            <TextField
              id="outlined-basic"
              label="Present Year"
              variant="outlined"
              type="number"
              size="small"
              value={volumes?.claimsPresentYear}
              onChange={(e) => setVolumes({ ...volumes, claimsPresentYear: parseInt(e.target.value, 10) })}
            />
            <TextField
              id="outlined-basic"
              label="Last Year"
              variant="outlined"
              type="number"
              size="small"
              value={volumes?.claimsLastYear}
              onChange={(e) => setVolumes({ ...volumes, claimsLastYear: parseInt(e.target.value, 10) })}
            />
            <TextField
              id="outlined-basic"
              label="Last To last Year"
              variant="outlined"
              type="number"
              size="small"
              value={volumes?.claimsLastToLastYear}
              onChange={(e) => setVolumes({ ...volumes, claimsLastToLastYear: parseInt(e.target.value, 10) })}
            />
          </div>
        </div>
      )}
      {clientInformation.businessType === 'rollover' && (
        <div>
          <Typography my={2} variant={'h6'}>
            Premium Details
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '10px',
            }}
          >
            <TextField
              id="outlined-basic"
              label="Present Year"
              variant="outlined"
              type="number"
              size="small"
              value={volumes?.premiumPresentYear}
              onChange={(e) => setVolumes({ ...volumes, premiumPresentYear: parseInt(e.target.value, 10) })}
            />
            <TextField
              id="outlined-basic"
              label="Last Year"
              variant="outlined"
              type="number"
              size="small"
              value={volumes?.premiumLastYear}
              onChange={(e) => setVolumes({ ...volumes, premiumLastYear: parseInt(e.target.value, 10) })}
            />
            <TextField
              id="outlined-basic"
              label="Last To last Year"
              variant="outlined"
              type="number"
              size="small"
              value={volumes?.premiumLastToLastYear}
              onChange={(e) => setVolumes({ ...volumes, premiumLastToLastYear: parseInt(e.target.value, 10) })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tab2;
