/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdown({
  options,
  selected,
  setSelected,
  label,
  style = {},
  endAddon,
  size = 'small',
  disabled = false,
}) {
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120, ...style }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" size={size}>
          {label} {endAddon}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          label={label || ''}
          onChange={handleChange}
          size={size}
          disabled={disabled}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label} {endAddon}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
