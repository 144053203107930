const { default: useCreateQuote } = require('./quotesStore');

const useTab1Validation = () => {
  const clientInformation = useCreateQuote((state) => state.clientInformation);
  if (!clientInformation?.lob) {
    return true;
  }
  if (!clientInformation?.geography) {
    return true;
  }
  if (!clientInformation?.clientName) {
    return true;
  }
  if (!clientInformation?.businessType) {
    return true;
  }
  if (!clientInformation?.industryType) {
    return true;
  }
  if (!clientInformation?.location) {
    return true;
  }
  if (!clientInformation?.groupCategory) {
    return true;
  }
  if (!clientInformation?.groupDetail) {
    return true;
  }
  if (!clientInformation?.typeOfRisk) {
    return true;
  }
  if (!clientInformation?.ageLimit) {
    return true;
  }

  return false;
};

export default useTab1Validation;
