import React, { useEffect, useRef, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { styled } from '@mui/material';
import useUsers from '../hooks/useUsers';

const StyledNode = styled('div')({
  padding: '15px',
  borderRadius: '8px',
  display: 'inline-block',
  border: '1px solid #2165d1',
  backgroundColor: '#f5f9ff',
});

const UserTree = () => {
  const { data } = useUsers(null, null, null, true);

  const firstRef = useRef(false);
  const [ownersData, setOwnersData] = useState({});
  const [admins, setAdmins] = useState([]);

  const [finalAdminMap, setFinalAdminMap] = useState(new Map());
  const [finalManagerMap, setFinalManagerMap] = useState(new Map());
  const [finalUnderWriterMap, setFinalUnderWriterMap] = useState(new Map());

  useEffect(() => {
    if (!data) return;
    if (firstRef.current) return;

    const { results } = data;
    const owner = results.filter((user) => user.role === 'owner')[0];

    const admins = results.filter((user) => user.role === 'admin');
    const managers = results.filter((user) => user.role === 'manager');
    const underwriters = results.filter((user) => user.role === 'underwriter');

    const users = results.filter((user) => user.role === 'user');

    setOwnersData(owner);

    const adminMap = new Map();
    const underwriterMap = new Map();
    const managerMap = new Map();

    users.forEach((user) => {
      user.underwriters.forEach((underwriter) => {
        const users = underwriterMap.get(underwriter);
        underwriterMap.set(underwriter, users ? [...users, user] : [user]);
      });
    });

    underwriters.forEach((underwriter) => {
      underwriter.managers.forEach((manager) => {
        const underwriters = managerMap.get(manager);
        managerMap.set(manager, underwriters ? [...underwriters, underwriter] : [underwriter]);
      });
    });

    managers.forEach((manager) => {
      manager.admins.forEach((admin) => {
        const managers = adminMap.get(admin);
        adminMap.set(admin, managers ? [...managers, manager] : [manager]);
      });
    });

    adminMap.delete(owner.id);
    setFinalAdminMap(adminMap);
    setFinalManagerMap(managerMap);
    setFinalUnderWriterMap(underwriterMap);

    setAdmins(admins.filter((admin) => ![...adminMap.keys()].includes(admin.id)));

    firstRef.current = true;
  }, [data]);
  return (
    <div>
      <Tree
        lineWidth={'2px'}
        lineColor={'#2165d1'}
        lineBorderRadius={'10px'}
        label={
          <StyledNode>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {ownersData?.name}
            </div>
            <div
              style={{
                fontStyle: 'italic',
                fontSize: '10px',
                textAlign: 'left',
              }}
            >
              Owner
            </div>
          </StyledNode>
        }
      >
        {[...finalAdminMap.keys()].map((admin) => (
          <TreeNode
            key={admin}
            label={
              <StyledNode>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  {data?.results?.find((adm) => adm.id === admin)?.name}
                </div>
                <div
                  style={{
                    fontStyle: 'italic',
                    fontSize: '10px',
                    textAlign: 'left',
                  }}
                >
                  Admin
                </div>
              </StyledNode>
            }
          >
            {finalAdminMap.get(admin).map((manager) => (
              <TreeNode
                key={manager.id}
                label={
                  <StyledNode>
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      {manager.name}
                    </div>
                    <div
                      style={{
                        fontStyle: 'italic',
                        fontSize: '10px',
                        textAlign: 'left',
                      }}
                    >
                      Manager
                    </div>
                  </StyledNode>
                }
              >
                {finalManagerMap.get(manager.id)?.map((underwriter) => (
                  <TreeNode
                    key={underwriter.id}
                    label={
                      <StyledNode>
                        <div
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }}
                        >
                          {underwriter?.name}
                        </div>
                        <div
                          style={{
                            fontStyle: 'italic',
                            fontSize: '10px',
                            textAlign: 'left',
                          }}
                        >
                          Under Writer
                        </div>
                      </StyledNode>
                    }
                  >
                    {finalUnderWriterMap.get(underwriter.id)?.map((user) => (
                      <TreeNode
                        key={user.id}
                        label={
                          <StyledNode>
                            <div
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                              }}
                            >
                              {user?.name}
                            </div>
                            <div
                              style={{
                                fontStyle: 'italic',
                                fontSize: '10px',
                                textAlign: 'left',
                              }}
                            >
                              User
                            </div>
                          </StyledNode>
                        }
                      />
                    ))}
                  </TreeNode>
                ))}
              </TreeNode>
            ))}
          </TreeNode>
        ))}
        {admins.map((admin) => (
          <TreeNode
            key={admin.id}
            label={
              <StyledNode>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  {admin?.name}
                </div>
                <div
                  style={{
                    fontStyle: 'italic',
                    fontSize: '10px',
                    textAlign: 'left',
                  }}
                >
                  Admin
                </div>
              </StyledNode>
            }
          />
        ))}
      </Tree>
    </div>
  );
};

export default UserTree;
// owner -> Admin -> Manager -> Underwriter -> User
